import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useLanguages } from '../hooks/useLanguages';
import Layout from '../components/layout';
import 'react-responsive-modal/styles.css';
import SEO from '../components/seo';
import HubspotForm from "react-hubspot-form"

export const query = graphql`
  query($id: ID!) {
    wpcontent {
      researchReport(id: $id) {
        title
        uri
        excerpt
        content
        keyPoints
        hsFormId
        region
        publishedYear
        reportThumbnail
        seo {
          title
          metaDesc
        }
      }
      researchReports(first: 3, where: {notIn: [$id]}) {
        nodes {
          publishedYear
          region
          reportThumbnail
          uri
          title
          slug
          id
        }
      }
    }
  }
`

const flags = {
  "USA": `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="30px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
<path style="fill:#F5F5F5;" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z"/>
<g>
    <path style="fill:#FF4B55;" d="M512,114.081H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,114.081L512,114.081z"/>
    <rect y="191.491" style="fill:#FF4B55;" width="512" height="25.804"/>
    <rect y="139.881" style="fill:#FF4B55;" width="512" height="25.804"/>
    <path style="fill:#FF4B55;" d="M512,268.903H8.828c-4.875,0-8.828-3.953-8.828-8.828v-16.977h512V268.903z"/>
    <rect y="346.321" style="fill:#FF4B55;" width="512" height="25.804"/>
    <path style="fill:#FF4B55;" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828v-16.976h512v16.977 C512,419.773,508.047,423.725,503.172,423.725z"/>
    <rect y="294.711" style="fill:#FF4B55;" width="512" height="25.804"/>
</g>
<path style="fill:#41479B;" d="M229.517,88.277H8.828C3.953,88.277,0,92.229,0,97.104v162.97c0,4.875,3.953,8.828,8.828,8.828 h220.69c4.875,0,8.828-3.953,8.828-8.828V97.104C238.345,92.229,234.392,88.277,229.517,88.277z"/>
</svg>`,

  "UK": `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px"
	 viewBox="0 0 512.002 512.002" style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
<path style="fill:#41479B;" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828
	h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.772,508.047,423.725,503.172,423.725z"/>
<path style="fill:#F5F5F5;" d="M512,97.104c0-4.875-3.953-8.828-8.828-8.828h-39.495l-163.54,107.147V88.276h-88.276v107.147
	L48.322,88.276H8.828C3.953,88.276,0,92.229,0,97.104v22.831l140.309,91.927H0v88.276h140.309L0,392.066v22.831
	c0,4.875,3.953,8.828,8.828,8.828h39.495l163.54-107.147v107.147h88.276V316.578l163.54,107.147h39.495
	c4.875,0,8.828-3.953,8.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512,119.935V97.104z"/>
<g>
	<polygon style="fill:#FF4B55;" points="512,229.518 282.483,229.518 282.483,88.276 229.517,88.276 229.517,229.518 0,229.518 
		0,282.483 229.517,282.483 229.517,423.725 282.483,423.725 282.483,282.483 512,282.483 	"/>
	<path style="fill:#FF4B55;" d="M178.948,300.138L0.25,416.135c0.625,4.263,4.14,7.59,8.577,7.59h12.159l190.39-123.586h-32.428
		V300.138z"/>
	<path style="fill:#FF4B55;" d="M346.388,300.138H313.96l190.113,123.404c4.431-0.472,7.928-4.09,7.928-8.646v-7.258
		L346.388,300.138z"/>
	<path style="fill:#FF4B55;" d="M0,106.849l161.779,105.014h32.428L5.143,89.137C2.123,90.54,0,93.555,0,97.104V106.849z"/>
	<path style="fill:#FF4B55;" d="M332.566,211.863L511.693,95.586c-0.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138,211.863
		H332.566z"/>
</g>
</svg>`,

  "UAE": `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512.001 512.001">
<path fill="#73AF00" d="M512,200.093H158.897V88.277h344.276c4.875,0,8.828,3.953,8.828,8.828L512,200.093z"/>
<path fill="#464655" d="M503.172,423.725H158.897V311.909H512v102.988C512,419.773,508.047,423.725,503.172,423.725z"/>
<rect x="158.9" y="200.091" fill="#F5F5F5" width="353.1" height="111.81"/>
<path fill="#FF4B55" d="M158.897,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828h150.069V423.725z"/>
</svg>`,

  "INDIA": `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512.001 512.001">
<path fill="#FAB446" d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828L512,200.093z"/>
<path fill="#73AF00" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988C512,419.773,508.047,423.725,503.172,423.725z"/>
<rect y="200.091" fill="#F5F5F5" width="512" height="111.81"/>
<path fill="#41479B" d="M256,303.449c-26.164,0-47.448-21.284-47.448-47.448s21.284-47.448,47.448-47.448
	s47.448,21.284,47.448,47.448S282.164,303.449,256,303.449z M256,217.38c-21.298,0-38.621,17.323-38.621,38.621
	c0,21.298,17.323,38.621,38.621,38.621s38.621-17.323,38.621-38.621C294.621,234.703,277.298,217.38,256,217.38z"/>
<circle fill="#41479B" cx="256" cy="256.001" r="5.379"/>
<polygon fill="#41479B" points="256,256.808 242.33,258.188 212.966,256.808 212.966,255.194 242.33,253.814 256,255.194"/>
<polygon fill="#41479B" points="256,256.808 269.67,258.188 299.034,256.808 299.034,255.194 269.67,253.814 256,255.194"/>
<polygon fill="#41479B" points="255.193,256.001 253.813,242.331 255.193,212.967 256.807,212.967 258.187,242.331 256.807,256.001"/>
<polygon fill="#41479B" points="255.193,256.001 253.813,269.671 255.193,299.035 256.807,299.035 258.187,269.671 256.807,256.001"/>
<polygon fill="#41479B" points="255.43,256.571 244.788,247.882 225,226.142 226.141,225.001 247.881,244.789 256.57,255.431"/>
<polygon fill="#41479B" points="255.43,256.571 264.119,267.213 285.859,287.001 287,285.86 267.212,264.12 256.57,255.431"/>
<polygon fill="#41479B" points="255.43,255.431 264.119,244.789 285.859,225.001 287,226.142 267.212,247.882 256.57,256.571"/>
<polygon fill="#41479B" points="255.43,255.431 244.788,264.12 225,285.86 226.141,287.001 247.881,267.213 256.57,256.571"/>
<circle fill="#F5F5F5" cx="256" cy="256.001" r="7.256"/>
<circle fill="#41479B" cx="256" cy="256.001" r="4.351"/>
</svg>
  `,
  "GLOBAL": `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 1707 1707">
<path fill="#80b4fb" d="m853 1707c-228 0-442-89-603-250s-250-376-250-604 89-442 250-603 375-250 603-250 443 89 604 250 250 375 250 603-89 443-250 604-376 250-604 250z"/>
<path fill="#73a2e2" d="m333 388c-5 0-11-2-16-6-9-9-9-24-1-33 69-73 149-130 240-170 94-41 194-62 297-62 13 0 24 10 24 23s-11 23-24 23c-192 0-371 78-503 218-4 5-10 7-17 7z"/>
<path fill="#73a2e2" d="m853 1590c-196 0-381-77-521-216-139-139-215-324-215-521 0-13 10-23 23-23s23 10 23 23c0 381 310 690 690 690 13 0 24 11 24 24s-11 23-24 23z"/>
<path fill="#a2f4c1" d="m1345 723c50-18 124-153 157-341 1-4 7-43 11-70-18-21-36-42-56-62-61-61-130-112-204-151-22 8-58 20-62 22-22 8-80 31-138 61-130 66-146 103-148 113-9 47 5 55 41 77 10 6 21 12 33 20 110 79 128 209-10 262-73 28-192 24-304-73-22-19-55-7-73 2-73 37-147 145-140 247v1s3 57 47 93c34 28 84 37 148 28 12-2 23 6 26 17 0 2 31 118 141 177 9 5 14 15 12 25-9 50-21 191 36 244 25 23 61 27 111 13h5c5-1 66-14 80-154 1-11 9-19 19-21 17-3 68-18 80-45 2-4 7-16-8-41-3-4-4-9-4-14s8-133 112-203c15-10 21-20 19-32-3-16-22-36-55-45-25-6-51-15-61-38-7-19-3-38 4-68 7-27 21-44 44-49 17-4 36 0 56 4 26 5 53 11 81 1z"/>
<path fill="#92dcae" d="m923 1366c-13 0-23-10-23-23s10-23 23-23c10-1 14-7 18-48 0-4 0-7 1-10l9-87c1-11 8-19 19-21l59-11c5-97 32-130 49-152 10-11 11-14 11-22-1-13-11-38-31-78-6-11-1-26 10-31 12-6 26-2 31 10 46 90 45 114 16 151-15 18-36 43-40 129v14c-1 11-9 20-20 22l-60 11-7 70c0 3 0 6-1 9-2 31-8 90-64 90zm-40-580c-93 0-188-34-271-97-10-8-12-23-4-33s23-12 33-4c74 57 159 87 242 87 12 0 23 0 35-1 79-9 181-54 212-155 26-88-15-180-111-248-3-2-7-5-11-7-11-7-15-22-8-33s21-14 32-7c6 3 10 6 14 9 113 80 161 192 128 299-16 53-50 99-98 133-44 30-97 49-154 55-13 1-26 2-39 2z"/>
</svg>

  `
}




const AireReportTemplate = ({ data, location }) => {

  const [currLanguage] = useLanguages()

    const title = data.wpcontent.researchReport.title
    const excerpt = data.wpcontent.researchReport.excerpt
    const publishedYear = data.wpcontent.researchReport.publishedYear
    const region = data.wpcontent.researchReport.region
    const reportThumbnail = data.wpcontent.researchReport.reportThumbnail
    const content = data.wpcontent.researchReport.content
    const keyPoints = data.wpcontent.researchReport.keyPoints
    const hsFormId = data.wpcontent.researchReport.hsFormId
    const seoTitle = data.wpcontent.researchReport.seo.title
    const seoMetaDesc = data.wpcontent.researchReport.seo.metaDesc

    const reports = data.wpcontent.researchReports.nodes
    const reportsTitle = data.wpcontent.researchReports.nodes.title
    const reportsPublishedYear = data.wpcontent.researchReports.nodes.publishedYear
    const reportsregion = data.wpcontent.researchReports.nodes.region
    const reportsThumbnail = data.wpcontent.researchReports.nodes.reportThumbnail
    let uri = data.wpcontent.researchReport.uri
    
    let baseUrl = "https://www.vantagecircle.com";

    let pageUri = baseUrl + uri

{
    return (
      <Layout location={location} pageLanguage={currLanguage}>
        {/* <SEO title={seoTitle}
            description={seoMetaDesc} 
              meta={[
                {
                  name: `description`,
                  content: seoMetaDesc,
                },
                {
                  property: `og:title`,
                  content: seoTitle,
                },
                {
                  property: `og:description`,
                  content: seoMetaDesc,
                },
                {
                  name: `twitter:title`,
                  content: seoTitle,
                },
                {
                  name: `twitter:description`,
                  content: seoMetaDesc,
                },
              ]}
              link={[
                {
                  rel: `canonical`,
                  href: pageUri,
                },
              ]}    
        /> */}
        
        <SEO title={seoTitle}
                description={seoMetaDesc}
                link={[
					{
						rel: `canonical`,
						href: pageUri,
					},
				]}
        />


        <section className='w-full lg:max-w-7xl mx-auto mt-5 px-10 py-10 xl:py-16 bg-purple-100 lg:flex'>
          <div className='w-full lg:w-7/12'>
            <h2 className="my-2 pl-1 text-center lg:text-left heroTagStyle text-center lg:text-left" style={{ color: '#64637f' }}>ANNUAL RECOGNITION AND REWARDS REPORT</h2>
            <h1 className='mt-5 lg:my-2 homepage-section-heading text-center lg:text-left'>{title}</h1>
            <div className=' my-5 text-center lg:text-left' dangerouslySetInnerHTML={{ __html: `${excerpt}` }}  />
            <div className='flex justify-center lg:justify-start mb-7 xl:mt-7'>
              <div dangerouslySetInnerHTML={{ __html: `${flags[region]}` }} /> <p className='ml-3 text-gray-500 text-md' style={{ transform: 'translateY(0px)' }}>{region} | {publishedYear}</p>
            </div>
          </div>
          <div className='w-full lg:w-5/12 grid justify-center lg:justify-end items-center'>
              <img src={reportThumbnail} width="400" />
          </div>
        </section>

        <section className='py-10 xl:py-16 w-full px-5 xl:px-0'>
          <div className='max-w-7xl border rounded-xl mx-auto lg:flex p-6 py-10 lg:p-10'>
              <div className='lg:mt-5'>
                <h2 className='homepage-div-heading mb-5 lg:mb-10'>You will get to learn:</h2>
                <ul className='mb-10 lg:mb-0 lg:mr-5 orangebullets' dangerouslySetInnerHTML={{ __html: `${keyPoints}` }} />
              </div>
              <div className='lg:ml-auto'>
                  <div class="w-full flex justify-center items-center shadow rounded-xl py-10 px-5 lato lighterDesignForm-blackBorder aireTemplateForm">
                      <div class="flex justify-center items-center">
                          <HubspotForm
                          portalId="6153052"
                          formId={hsFormId}
                          onSubmit={() => console.log("Submit!")}
                          onReady={form => console.log("Form ready!")}
                          loading={<div>Loading...</div>}
                          />
                      </div>
                  </div>
              </div>
          </div>
        </section>

        <section className='pb-10 xl:pb-16 w-full px-6'>
            <div className='max-w-7xl mx-auto'>
                <h2 className='homepage-section-heading'>
                  Inside the report
                </h2>
                <ul className='md:grid grid-cols-2 lg:grid-cols-3 mt-5 purplebullets' dangerouslySetInnerHTML={{ __html: `${content}` }}  />
            </div>
        </section>

        <section className='pb-10 xl:pb-16 w-full px-5'>
          <div className='max-w-7xl mx-auto'>
            <h2 className='homepage-section-heading text-center pb-12'>Explore other reports</h2>
            <div className='md:grid md:grid-cols-2 lg:grid-cols-3 gap-10'>
              {
                  reports?.map(report => {
                    return(
                      <a href={report.uri}>
                        <div className="cursor-pointer rounded-xl shadow bg-white p-5 relative h-auto min-h-550px xl:min-h-600px hover:scale-110 transform transition duration-500 mb-9 md:my-2 lg:my-0">
                          <img src={report.reportThumbnail} />
                          <p className="m-0 pb-1 pt-4 uppercase" style={{ color: '#b4b4b4 !important', lineHeight: '32px', fontWeight: '500', letterSpacing: '2.5px', fontSize: '15px', opacity: '0.85' }}>{report.region}</p>
                          <h2 className="homepage-small-heading truncate text-line-clamp-2 overflow-hidden text-gray-500">{report.title}</h2>
                          <a className="pt-10 text-orange xl:absolute bottom-6" href={report.uri}>Download</a>
                        </div>
                      </a>
                    )
                  })
              }
            </div>
          </div>
        </section>

        <section className='pb-10 md:py-10 w-full px-5 lg:px-0'>
          <div className='w-full lg:w-11/12 lg:max-w-7xl mx-auto bg-indigo-100 lg:flex justify-around rounded-xl p-7 pt-10 pb-0 lg:px-5 xl:p-0'>
              <div className='my-auto grid justify-center lg:justify-start'>
                  <h2 className='homepage-div-heading text-purple-100 text-center lg:text-left'>Take AIR<sup>e</sup> Assessment today</h2>
                  <p className='text-purple-100 pt-3 pb-5 text-center lg:text-left'>And receive a data-backed quality score for your RnR program.</p>
                  <a href='https://www.vantagecircle.com/tools/aire-score-assessment/' className='text-center rounded-full p-4 text-purple-100 border border-purple-100 lg:w-1/2'>
                    Assess my program <span className='text-lightgreen text-sm font-bold'> &nbsp; &nbsp;FREE!</span>
                  </a>
              </div>

              <div className='flex items-end justify-center pt-10 lg:pt-3'>
                  <picture>
                      <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="450" />
                      <img className='mx-auto mt-2 z-10' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp" alt="Vantage Perks" width="300" />
                  </picture>
              </div>
          </div>
        </section>
      </Layout>
    )
  }

}

export default AireReportTemplate